<template>
  <div class="group--page">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Predefined Groups
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        color="primary"
        @click.stop="addNewOrEditGroup = true"
        depressed
        large
      >
        <v-icon left dark> {{ icons.plus }} </v-icon>
        Add New Group
      </v-btn>
    </v-app-bar>

    <v-divider class="my-5" />

    <router-view />

    <v-dialog v-model="addNewOrEditGroup" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Add New Group </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="3">
              <v-select
                v-model="form.emoji"
                :loading="loading"
                :items="emojis"
                label="Emoji"
                item-text="value"
                item-value="id"
                hide-details
                cache-items
                outlined
                flat
              />
            </v-col>

            <v-col cols="9">
              <v-text-field
                v-model="form.name"
                label="Group Name"
                :error-messages="form.$getError('name')"
                :hide-details="!form.$hasError('name')"
                clearable
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn color="error darken-1" text @click="addNewOrEditGroup = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary text-white"
            @click="save()"
            :loading="form.$busy"
          >
            Save Group
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import Emoji from '@/models/Emoji'
import Group from '@/models/Group'
import Form from '@/utils/form'

import { mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import { merge } from 'lodash'

export default {
  name: 'GroupPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      addNewOrEditGroup: false,
      loading: false,
      search: '',
      emojis: [],

      icons: {
        plus: mdiPlus,
      },

      form: new Form({
        id: null,
        emoji: '',
        name: '',
        is_default: true,
      }),
    }
  },

  created() {
    this.fetchEmojis()
  },

  computed: {
    groupDetails() {
      return this.$store.state.groups.groupDetails
    },
  },

  methods: {
    ...mapMutations('groups', ['saveOrUpdateGroup', 'clearGroupDetails']),

    async fetchEmojis() {
      this.loading = true

      try {
        const { data } = await Emoji.get()

        this.emojis = data
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    async save() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        const group = await new Group(this.form).save()

        this.saveOrUpdateGroup(group)

        this.form.$busy = false
        this.addNewOrEditGroup = false

        this.form.$reset()
      } catch (err) {
        this.form.$busy = false

        if (err.response.status === 422) {
          this.form.$setErrors(err.response.data.errors)
        }
      }
    },
  },

  watch: {
    search(val) {
      val && val !== this.select && this.fetchEmojis(val)
    },

    groupDetails({ id, name, emoji }) {
      if (!id) {
        return (this.addNewOrEditGroup = false)
      }

      this.form = merge(this.form, {
        id,
        name,
        emoji: emoji.id,
      })

      this.addNewOrEditGroup = true
    },

    addNewOrEditGroup(open) {
      if (!open && this.groupDetails.id) {
        this.clearGroupDetails()
        this.form.$reset()
      }
    },
  },
}
</script>
